export const BASE_URL = process.env.REACT_APP_BACKEND_API;
export const CLOUDINARY_UPLOAD_PRESET =
  process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
export const CLOUDINARY_CLOUD_NAME =
  process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
export const NUMBER_POSTS_OF_LIMIT =
  process.env.REACT_APP_NUMBER_POSTS_OF_LIMIT;
export const NUMBER_BLOGS_OF_LIMIT =
  process.env.REACT_APP_NUMBER_BLOGS_OF_LIMIT;
export const CHATGPT_API_KEY = process.env.REACT_APP_CHATGPT_API_KEY;
export const CHATGPT_URL = process.env.REACT_APP_CHATGPT_URL;
export const FACEBOOK_URL = process.env.REACT_APP_FACEBOOK_URL;
export const THANK_YOU_EMAIL = process.env.REACT_APP_THANK_YOU_EMAIL;
export const GOOGLE_MAP_URL = process.env.REACT_APP_GOOGLE_MAP_URL;
export const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL;
